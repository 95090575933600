<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st1" d="M161.3,164.8h-9.6c-3,0-5.5-2.5-5.5-5.5v-14.4h-27.7v14.4c0,3-2.5,5.5-5.5,5.5h-9.6c-1.8,0-3.3,1.5-3.3,3.3   c0,1.8,1.5,3.3,3.3,3.3h57.9c1.8,0,3.3-1.5,3.3-3.3C164.6,166.3,163.1,164.8,161.3,164.8z"/>
      <g>
        <path class="st0" d="M40.9,129.5c-2.9,0-5.2,2.3-5.2,5.2s2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2S43.8,129.5,40.9,129.5z"/>
        <path class="st0" d="M60.5,137.8V56c0-3.9,3.2-7.1,7.1-7.1h13.1V29.7c0-3-2.4-5.4-5.4-5.4H6.5c-3,0-5.4,2.4-5.4,5.4v140.7    c0,3,2.4,5.4,5.4,5.4h68.7c3,0,5.4-2.4,5.4-5.4v-25.5H67.6C63.7,144.9,60.5,141.7,60.5,137.8z M40.9,142.8c-4.4,0-8-3.6-8-8    s3.6-8,8-8s8,3.6,8,8S45.3,142.8,40.9,142.8z M53.3,114.4H15.5c-1.5,0-2.8-1.2-2.8-2.8s1.2-2.8,2.8-2.8h37.8    c1.5,0,2.8,1.2,2.8,2.8S54.8,114.4,53.3,114.4z M53.3,100.3H15.5c-1.5,0-2.8-1.2-2.8-2.8s1.2-2.8,2.8-2.8h37.8    c1.5,0,2.8,1.2,2.8,2.8S54.8,100.3,53.3,100.3z M53.3,86.3H15.5c-1.5,0-2.8-1.2-2.8-2.8c0-1.5,1.2-2.8,2.8-2.8h37.8    c1.5,0,2.8,1.2,2.8,2.8C56,85.1,54.8,86.3,53.3,86.3z M56,69.6c0,1.5-1.2,2.8-2.8,2.8H15.5c-1.5,0-2.8-1.2-2.8-2.8l0,0    c0-1.5,1.2-2.8,2.8-2.8h37.8C54.8,66.8,56,68.1,56,69.6L56,69.6z M53.3,58.3H15.5c-1.5,0-2.8-1.2-2.8-2.8s1.2-2.8,2.8-2.8h37.8    c1.5,0,2.8,1.2,2.8,2.8S54.8,58.3,53.3,58.3z"/>
        <path class="st0" d="M195.7,53.7H69c-1.8,0-3.2,1.4-3.2,3.2v79.9c0,1.8,1.4,3.2,3.2,3.2h126.7c1.8,0,3.2-1.4,3.2-3.2V56.9    C198.9,55.2,197.4,53.7,195.7,53.7z M132.3,137.5c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8    C135.1,136.3,133.9,137.5,132.3,137.5z M193.2,125.7c0,1.8-1.4,3.2-3.2,3.2H74.7c-1.8,0-3.2-1.4-3.2-3.2V62c0-1.8,1.4-3.2,3.2-3.2    h115.2c1.8,0,3.2,1.4,3.2,3.2V125.7z"/>
        <path class="st0" d="M148,145.7c0-0.4-0.4-0.8-0.8-0.8H144h-24.5h-2.6c-0.4,0-0.8,0.4-0.8,0.8c0,15.9-6.1,23.2-9.3,25.9    c-0.6,0.5-0.2,1.4,0.5,1.4h50c0.7,0,1.1-0.9,0.5-1.4C148.7,163.6,148,149.2,148,145.7z"/>
      </g>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconComputer',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

